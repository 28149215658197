import React, {useContext, useEffect, useState} from "react";
import {API_ADDRESS, AppContext} from "../../App";
import ContactModalButton from "../Contact/ContactModalButton";

function Drawer() {
    const {drawerIsOpen, setDrawerIsOpen} = useContext(AppContext);

    const inPromotion = (window.location.href.includes("promotion/"));

    const [promotionMenu, setPromotionMenu] = useState(false);

    const [promotions, setPromotions] = useState([]);

    let menuLinks;
    if (!inPromotion){
        menuLinks = [
            {url: "/", title: "Accueil"},
            {url: "/acheter", title: "Acheter"},
            {url: "/louer", title: "Louer"},
            {url: "/vendre", title: "Vendre"},
            {url: "/vendus", title: "Vendus"},
            {url: "/equipe", title: "Notre équipe"},
        ];
    }
    else{
        let base = "/promotion/" + window.location.pathname.split("/")[2];
        menuLinks = [
            {url: base, title: "Accueil promotion"},
            {url: base + "/projet", title: "Projet"},
            {url: "/promotions", title: "Toutes les promotions"},
            {url: "/", title: "Retour au site"}
        ];
    }

    useEffect(() => {
        const fetchData = async () => {
            fetch(API_ADDRESS + '/promotions')
                .then(res => res.json())
                .then(json => {
                    setPromotions(json.data);
                });
        };

        fetchData();
    }, []);

    const handlePromotionsToggle = () => {
        setPromotionMenu(!promotionMenu);
    };

    if (!drawerIsOpen){
        return (<div></div>);
    }

    return (
        <div id="drawer" className="fixed top-0 left-0 z-50 h-screen p-4 overflow-y-auto transition-transform bg-white w-64"
             tabIndex="-1">
            <a href="/">
                <img src={require('../../media/images/icons/logo-no-bg.png')} className="h-20" alt="Foti Immobilier"/>
            </a>
            <button type="button" onClick={() => setDrawerIsOpen(false)}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span className="sr-only">Close menu</span>
            </button>
            <div className="py-4 overflow-y-auto">
                <ul className="space-y-2 font-medium">
                    {menuLinks.map((link, index) => (
                        <li key={index} onClick={() => setDrawerIsOpen(false)}>
                            <a href={link.url}
                               className="flex items-center p-2 text-primary hover:bg-gray-100 group">
                                <span className="ml-3">{link.title}</span>
                            </a>
                        </li>
                    ))}
                    <li onClick={handlePromotionsToggle}>
                        <button className={
                            "flex items-center p-2 text-primary hover:bg-gray-100 group ml-3 " +
                            (inPromotion ? " hidden" : "")
                        }>
                            Promotions
                        </button>

                        <div className={"z-10 bg-white " + (promotionMenu ? "" : "hidden")}>
                            <ul className="text-sm text-gray-700" aria-labelledby="dropdownHoverButton">
                                <li>
                                    <a href={"/promotions"} className={"block px-4 py-2 text-primary font-bold"}>
                                        Voir toutes les promotions
                                    </a>
                                </li>
                                {promotions.length > 0 ?
                                    <>
                                        {promotions.map((promotion, index) => (
                                            <li key={index}>
                                                <a href={"/promotion/" + promotion.ref} className={"block px-4 py-2 text-primary"}>
                                                    {promotion.title}
                                                </a>
                                            </li>
                                        ))}
                                    </>
                                    :
                                    ""
                                }
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a href="tel:0041245658008" className="flex items-center p-2 text-primary hover:bg-gray-100 group ml-3">
                            <svg className={"w-6 h-6 mr-1 mb-1 fill-primary group-hover:fill-primary-white"}
                                 fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path clipRule="evenodd" fillRule="evenodd"
                                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"></path>
                            </svg>
                            +41 24 565 8008
                        </a>
                    </li>

                    <li>
                        <div className="p-2 ml-3 group" onClick={() => setDrawerIsOpen(false)}>
                            <ContactModalButton />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Drawer;
