import SearchForm from "../components/Property/SearchForm/SearchForm";
import "../media/styles/Home.css";
import React, {useEffect, useState} from "react";
import PropertyList from "../components/Property/PropertyList";
import {API_ADDRESS} from "../App";

function Home() {
    window.history.replaceState({}, document.title, "/");

    const [properties, setProperties] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            fetch(API_ADDRESS + '/properties/home')
                .then(res => res.json())
                .then(json => {
                    setProperties(json.data);
                    setIsLoading(false);
                });
        };

        fetchData();
    }, []);

    return (
        <div className="h-full">
            <div className="relative h-full">
                <img draggable="false" unselectable="on" className="h-screen w-full object-none md:object-fill"
                     src={process.env.PUBLIC_URL + "/images/home_2-min.jpg"} alt="..."/>
                <div className="absolute top-48 md:pt-12 left-0 md:top-96 md:left-48 w-full md:w-3/5 h-1/2">
                    <div className="flex justify-center md:justify-start">
                        <div className="text-white uppercase home-title font-bold text-3xl md:text-5xl w-5/6 mb-4">
                            à votre service pour vendre vos biens immobiliers.
                        </div>
                    </div>
                    <div className="flex md:block justify-center">
                        <SearchForm/>
                    </div>
                </div>
                <div>
                </div>
                <div className="py-10">
                    <PropertyList isLoading={isLoading} properties={properties}/>
                </div>
            </div>
        </div>
    );
}

export default Home;
