import React, {useContext, useEffect, useState} from "react";
import ContactModalButton from "../Contact/ContactModalButton";
import {API_ADDRESS, AppContext} from "../../App";

export const MIN_POS = 150;

function NavBar() {
    const {drawerIsOpen, setDrawerIsOpen} = useContext(AppContext);

    const [scrollPosition, setScrollPosition] = useState(0);

    const [promotionMenu, setPromotionMenu] = useState(false);

    const [promotions, setPromotions] = useState([]);

    const homePage = (window.location.pathname === "/");

    const inPromotion = (window.location.href.includes("promotion/"));

    let menuLinks;
    if (!inPromotion){
        menuLinks = [
            {url: "/", title: "Accueil"},
            {url: "/acheter", title: "Acheter"},
            {url: "/louer", title: "Louer"},
            {url: "/vendre", title: "Vendre"},
            {url: "/vendus", title: "Vendus"},
            {url: "/equipe", title: "Notre équipe"},
        ];
    }
    else{
        let base = "/promotion/" + window.location.pathname.split("/")[2];
        menuLinks = [
            {url: base, title: "Accueil promotion"},
            {url: base + "/projet", title: "Projet"},
            {url: "/promotions", title: "Toutes les promotions"},
            {url: "/", title: "Retour au site"}
        ];
    }

    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    const handlePromotionsHover = () => {
        setPromotionMenu(true);
    };

    const handlePromotionsBlur = () => {
        setPromotionMenu(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        const fetchData = async () => {
            fetch(API_ADDRESS + '/promotions')
                .then(res => res.json())
                .then(json => {
                    setPromotions(json.data);
                });
        };

        fetchData();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={"bg-" + (scrollPosition < MIN_POS && homePage ? "transparent" : "white") + " border-gray-200 fixed w-full z-50 top-0 left-0"}>
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <a href="/" className="flex items-center">
                    <img src={require(scrollPosition < MIN_POS && homePage ? '../../media/images/icons/logo_white.png' : '../../media/images/icons/logo-no-bg.png')} className="h-20 md:h-36 mr-3" alt="Foti Immobilier"/>
                </a>
                <div className="w-auto">
                    <ul className="hidden p-0 flex-row space-x-6 md:flex">
                        {menuLinks.map((link, index) => (
                            <li key={index}>
                                <a href={link.url}
                                   className={
                                    "block py-2 pl-3 pr-3 underline-offset-2 text-xs md:text-base " +
                                       (scrollPosition < MIN_POS && homePage ? "text-white hover:text-primary" : "text-primary hover:text-white hover:bg-primary") +
                                       (window.location.pathname === link.url ? " underline" : "")
                                }>
                                    {link.title}
                                </a>
                            </li>
                        ))}
                            <li onMouseEnter={handlePromotionsHover} onMouseLeave={handlePromotionsBlur}>
                                <a className={
                                    "py-2 pl-3 pr-3 underline-offset-2 text-xs md:text-base inline-flex " +
                                    (scrollPosition < MIN_POS && homePage ? "text-white hover:text-primary" : "text-primary hover:text-white hover:bg-primary") +
                                    (inPromotion ? " hidden" : "")
                                } href="/promotions">
                                    Promotions
                                </a>

                                <div className={"z-10 bg-white absolute " + (promotionMenu ? "" : "hidden")}>
                                    <ul className="text-sm text-gray-700" aria-labelledby="dropdownHoverButton">
                                        {promotions.length > 0 ?
                                            <>
                                                {promotions.map((promotion, index) => (
                                                    <li key={index}>
                                                        <a href={"/promotion/" + promotion.ref} className={"block px-4 py-2 text-primary hover:text-white hover:bg-primary "}>
                                                            {promotion.title}
                                                        </a>
                                                    </li>
                                                ))}
                                            </>
                                            :
                                            ""
                                        }
                                    </ul>
                                </div>
                            </li>
                            <li className="flex justify-center items-center">
                                <a href="tel:0041245658008">
                                    <div className={"flex justify-center items-center hover:cursor-pointer group py-2 pl-3 pr-3 underline-offset-2 text-xs md:text-base " + (scrollPosition < MIN_POS && homePage ? "text-white hover:text-primary" : "text-primary hover:text-white hover:bg-primary")}>
                                        <svg className={"w-5 h-5 mr-1 mb-1 "+ (scrollPosition < MIN_POS && homePage ? "fill-white group-hover:fill-primary" : "fill-primary group-hover:fill-white")}
                                             fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path clipRule="evenodd" fillRule="evenodd"
                                                  d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"></path>
                                        </svg>
                                        +41 24 565 8008
                                    </div>
                                </a>
                            </li>
                            <li className="flex justify-center items-center group">
                                    <ContactModalButton scrollPosition={scrollPosition} />
                            </li>
                    </ul>
                    <div className="block md:hidden">
                        <button onClick={() => setDrawerIsOpen(!drawerIsOpen)}>
                            <svg className="w-6 h-6 text-primary hover:text-primary-light"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
